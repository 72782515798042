<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Textos Institucionais</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form
      ref="form"
    >
      <v-row
        justify="start"
        no-gutters
        class="pa-2"
      >
        <v-col
          class="d-flex"
          lg="2"
          md="2"
        />
      </v-row>

      <v-row
        justify="center"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex"
          cols="12"
          sm="4"
        >
          <v-select
            v-if="!institutionalText.category"
            v-model="selectedCategory"
            prepend-icon="mdi-shape-outline"
            :items="categories"
            :rules="$rules.required"
            required
            item-text="name"
            item-value="id"
            label="Categorias *"
            class="form-control"
            solo
          />
          <v-text-field
            v-else
            :value="institutionalText.category.name"
            label="Categoria *"
            disabled
          />
        </v-col>
        <v-col
          class="d-flex"
          cols="1"
          sm="1"
        />
        <v-col
          class="d-flex"
          cols="11"
          sm="4"
        >
          <v-select
            v-model="institutionalText.targetProducts"
            prepend-icon="mdi-shape"
            :items="products"
            :rules="$rules.required"
            required
            item-text="title"
            label="Produtos-Cassems*"
            class="form-control"
            return-object
            multiple
            solo
          />
        </v-col>
      </v-row>
      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      />
    </v-form>
    <v-row
      class="mt-5"
      justify="center"
      no-gutters
    >
      <v-col
        class="pr-5"
        cols="4"
        sm="8"
      >
        <v-text-field
          v-model="institutionalText.title"
          prepend-icon="mdi-format-title"
          outlined
          dense
          label="Titulo do Texto *"
          :rules="$rules.required"
          required
          :items="institutionalText"
          item-text="title"
          item-value="id"
        />
      </v-col>
    </v-row>
    <v-row
      class="mt-5"
      justify="center"
      no-gutters
    >
      <v-col
        class="pr-5"
        cols="4"
        sm="8"
      >
        <v-text-field
          v-if="institutionalText.slug"
          :value="institutionalText.slug"
          outlined
          dense
          label="Slug"
          disabled
        />
      </v-col>
    </v-row>
    <v-row
      class="mt-5"
      justify="center"
      no-gutters
    >
      <v-col
        class="pr-5"
        cols="4"
        sm="8"
      >
        <kosmo-editor
          v-model="institutionalText.text"
          label="Texto Institucional *"
          :rules="$rules.required"
          required
          item-text="text"
          item-value="id"
          :items="institutionalText"
        />
      </v-col>
    </v-row>
    <v-row
      class="mt-5"
      justify="center"
      no-gutters
    >
      <v-col
        class="pr-5"
        cols="12"
        sm="7"
      >
        <v-file-input
          v-model="imagesUpload"
          outlined
          :images="imagesPreview"
          label="Clique para adicionar imagens do tamanho mínimo de 1024x768"
          prepend-icon="mdi-camera"
          @change="onFilesSelected"
        />
      </v-col>
    </v-row>
    <v-row
      class="mt-5"
      justify="center"
      no-gutters
    >
      <v-col
        class="pr-5"
        cols="12"
        sm="6"
      >
        <gallery
          :images="imagesPreview"
          @image-deleted="onDeleteImage"
        />
      </v-col>
    </v-row>

    <v-row
      class="mt-10"
      justify="center"
      no-gutters
    >
      <v-card-actions>
        <v-btn
          color="secondary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          :loading="isLoading"
          @click="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-row>
  </v-card>
</template>

<script>

import { createInstitutionalPhoto } from '@/services/Intitutional-text-photo';
import CategoryService from '../../../services/category.service';
import InstitutionalTextService from '../../../services/institutional-text.service';
import TargetProductService from '../../../services/target-product.service';

export default {
  name: 'InstitutionalTextForm',
  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      institutionalText: {},
      categories: [],
      selectedCategory: {},
      products: [],
      imagesUpload: null,
      imagesPreview: [],
      isLoading: false,
    };
  },

  async created() {
    if (this.populateWith.id) {
      this.institutionalText = this.populateWith;
      this.imagesPreview = this.institutionalText?.link ? [this.institutionalText?.link] : [];
    }
    await this.loadCategories();
    await this.loaProducts();
  },

  methods: {
    close() {
      this.$emit('close-dialog');
    },

    onFilesSelected(files) {
      this.imagesPreview = [URL.createObjectURL(files)];
    },

    onDeleteImage(index) {
      this.imagesPreview.splice(index, 1);
      if (this.imagesUpload) {
        this.imagesUpload.splice(index, 1);
      }

      this.institutionalText = { ...this.institutionalText, link: null };
    },

    async loadCategories() {
      this.categories = await CategoryService.getCategory();
    },

    async loaProducts() {
      this.products = await TargetProductService.findAll();
    },

    async submit() {
      this.isLoading = true;
      if (!this.$refs.form.validate()) {
        this.isLoading = false;
        this.$toast.error('Verifique os campos e tente novamente');
        return;
      }
      if (this.populateWith.id) {
        try {
          if (this.imagesUpload) {
            const { link } = await createInstitutionalPhoto(this.imagesUpload);
            await InstitutionalTextService.updateInstitutionalText({
              ...this.institutionalText,
              title: this.institutionalText.title,
              text: this.institutionalText.text,
              targetProducts: this.institutionalText.targetProducts,
              link,
            });
            this.$emit('institutionalText-edited');
            this.$toast.success('Texto Institucional editado com sucesso');
            this.close();
          }
          await InstitutionalTextService.updateInstitutionalText({
            ...this.institutionalText,
            title: this.institutionalText.title,
            text: this.institutionalText.text,
            targetProducts: this.institutionalText.targetProducts,
            link: this.imagesUpload,
          });
          this.$emit('institutionalText-edited');
          this.isLoading = false;
          this.$toast.success('Texto Institucional editado com sucesso');
          this.close();
        } catch (e) {
          this.isLoading = false;
          this.$handleHttpError(e);
        }
      } else {
        try {
          if (this.imagesUpload) {
            const { link } = await createInstitutionalPhoto(this.imagesUpload);
            await InstitutionalTextService.createInstitutionalText({
              ...this.institutionalText,
              title: this.institutionalText.title,
              text: this.institutionalText.text,
              category: this.selectedCategory,
              targetProducts: this.institutionalText.targetProducts,
              link,
            });

            this.$emit('institutionalText-added', {
              ...this.institutionalText,
              title: this.institutionalText.title,
              text: this.institutionalText.text,
              category: this.selectedCategory,
              targetProducts: this.institutionalText.targetProducts,
              link,
            });
            this.isLoading = false;
            this.$toast.success('Texto Institucional cadastrada com sucesso');
            this.close();
          }
          await InstitutionalTextService.createInstitutionalText({
            ...this.institutionalText,
            title: this.institutionalText.title,
            text: this.institutionalText.text,
            category: this.selectedCategory,
            targetProducts: this.institutionalText.targetProducts,
            link: this.imagesUpload,
          });

          this.$emit('institutionalText-added', {
            ...this.institutionalText,
            title: this.institutionalText.title,
            text: this.institutionalText.text,
            category: this.selectedCategory,
            targetProducts: this.institutionalText.targetProducts,
            link: this.imagesUpload,
          });
          this.isLoading = false;
          this.$toast.success('Texto Institucional cadastrada com sucesso');
          this.close();
        } catch (e) {
          this.isLoading = false;
          this.$handleHttpError(e);
        }
      }
    },
  },
};
</script>
